<template>
      <div class="js-slider stage-video block-stage block-stage--home" data-slider-stage data-slider-stage-disable="stage-video--disable-nav" data-slider-video data-slider-autoplay="true" data-slider-stage-playing="stage-video--playing" >
        <div class="js-slider-items block-stage__items stage-video__items">
            <div class="block-stage__item">
              <picture class="block-stage__picture lazy-placeholder-loader ">
                <source media="(max-width: 360px)" data-srcset="/imagenes/slide_home/1/5.png">
                <source media="(max-width: 479px)" data-srcset="/imagenes/slide_home/1/4.png">
                <source media="(max-width: 1024px)" data-srcset="/imagenes/slide_home/1/2.png">
                <source media="(min-width: 1025px)" data-srcset="/imagenes/slide_home/1/1.png">
                <img src="" alt="The groundbreaking ceremony" class="image-covers-area js-lazy-load lazy-placeholder-loader__content">
              </picture>
              <div class="block-stage__content wrapper wrapper--gutter">
              </div> 
            </div>
            <div class="block-stage__item hide-til-slick-ready">
              <picture class="block-stage__picture lazy-placeholder-loader ">
                <source media="(max-width: 360px)" data-srcset="/imagenes/slide_home/2/5.png">
                <source media="(max-width: 479px)" data-srcset="/imagenes/slide_home/2/4.png">
                <source media="(max-width: 1024px)" data-srcset="/imagenes/slide_home/2/2.png">
                <source media="(min-width: 1025px)" data-srcset="/imagenes/slide_home/2/1.png">
                <img src="" alt="The groundbreaking ceremony" class="image-covers-area js-lazy-load lazy-placeholder-loader__content">
              </picture>
              <div class="block-stage__content wrapper wrapper--gutter">
              </div> 
            </div>
            <div class="block-stage__item hide-til-slick-ready">
              <picture class="block-stage__picture lazy-placeholder-loader ">
                <source media="(max-width: 360px)" data-srcset="/imagenes/slide_home/3/5.png">
                <source media="(max-width: 479px)" data-srcset="/imagenes/slide_home/3/4.png">
                <source media="(max-width: 1024px)" data-srcset="/imagenes/slide_home/3/2.png">
                <source media="(min-width: 1025px)" data-srcset="/imagenes/slide_home/3/1.png">
                <img src="" alt="Two roboters" class="image-covers-area js-lazy-load lazy-placeholder-loader__content">
              </picture>
              <div class="block-stage__content wrapper wrapper--gutter">
              </div> 
            </div>
            <div class="block-stage__item hide-til-slick-ready">
              <picture class="block-stage__picture lazy-placeholder-loader ">
                <source media="(max-width: 360px)" data-srcset="/imagenes/slide_home/4/5.png">
                <source media="(max-width: 479px)" data-srcset="/imagenes/slide_home/4/4.png">
                <source media="(max-width: 1024px)" data-srcset="/imagenes/slide_home/4/2.png">
                <source media="(min-width: 1025px)" data-srcset="/imagenes/slide_home/4/1.png">
                <img src="" alt="DB Schenker employee inside an cargo airplane" class="image-covers-area js-lazy-load lazy-placeholder-loader__content">
              </picture>
              <div class="block-stage__content wrapper wrapper--gutter">
              </div> 
            </div>
            <div class="block-stage__item hide-til-slick-ready">
              <picture class="block-stage__picture lazy-placeholder-loader ">
                <source media="(max-width: 360px)" data-srcset="/imagenes/slide_home/5/5.png">
                <source media="(max-width: 479px)" data-srcset="/imagenes/slide_home/5/4.png">
                <source media="(max-width: 1024px)" data-srcset="/imagenes/slide_home/5/2.png">
                <source media="(min-width: 1025px)" data-srcset="/imagenes/slide_home/5/1.png">
                <img src="" alt="181127DBsC4_v4" class="image-covers-area js-lazy-load lazy-placeholder-loader__content">
              </picture>
              <div class="block-stage__content wrapper wrapper--gutter">
              </div> 
            </div>
            <div class="block-stage__item hide-til-slick-ready">
              <picture class="block-stage__picture lazy-placeholder-loader ">
                <source media="(max-width: 360px)" data-srcset="/imagenes/slide_home/6/5.png">
                <source media="(max-width: 479px)" data-srcset="/imagenes/slide_home/6/4.png">
                <source media="(max-width: 1024px)" data-srcset="/imagenes/slide_home/6/2.png">
                <source media="(min-width: 1025px)" data-srcset="/imagenes/slide_home/6/1.png">
                <img src="" alt="Infobanner Coronavirus DB Schenker" class="image-covers-area js-lazy-load lazy-placeholder-loader__content">
              </picture>
              <div class="block-stage__content wrapper wrapper--gutter">
              </div> 
            </div>
            <div class="block-stage__item hide-til-slick-ready">
              <picture class="block-stage__picture lazy-placeholder-loader ">
                <source media="(max-width: 360px)" data-srcset="/imagenes/slide_home/7/5.png">
                <source media="(max-width: 479px)" data-srcset="/imagenes/slide_home/7/4.png">
                <source media="(max-width: 1024px)" data-srcset="/imagenes/slide_home/7/2.png">
                <source media="(min-width: 1025px)" data-srcset="/imagenes/slide_home/7/1.png">
                <img src="" alt="Infobanner Coronavirus DB Schenker" class="image-covers-area js-lazy-load lazy-placeholder-loader__content">
              </picture>
              <div class="block-stage__content wrapper wrapper--gutter">
              </div> 
            </div>				
        </div>
      
        <div class="stage-video__nav">
          <span class="stage-video__nav-item">
            <button class="arrow-left js-slider-prev stage-video__prev" >
              <svg class="icon icon--arrow-left icon--svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 23 43">
                <path d="M.45 20l20-20 2.1 2.1L3.65 21l18.9 19-2.1 2.1-20-20c-.6-.6-.6-1.5 0-2.1z"/>
              </svg>
            </button>
          </span>
          <span class="stage-video__nav-item">
            <nav class="bullets stage-video__bullets js-slider-pagination ">
              <button class="bullet bullet--active">
                <span class="bullet__circle"></span>
              </button>
              <button class="bullet">
                <span class="bullet__circle"></span>
              </button>
              <button class="bullet">
                <span class="bullet__circle"></span>
              </button>
              <button class="bullet">
                <span class="bullet__circle"></span>
              </button>
              <button class="bullet">
                <span class="bullet__circle"></span>
              </button>
              <button class="bullet">
                <span class="bullet__circle"></span>
              </button>
              <button class="bullet">
                <span class="bullet__circle"></span>
              </button>
            </nav>
          </span>
          <span class="stage-video__nav-item">
            <button class="arrow-right js-slider-next stage-video__next" >
              <svg class="icon icon--arrow-right icon--svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22.6 42.1">
                <path d="M22.1 20L2.1 0 0 2.1 18.9 21 0 40l2.1 2.1 20-20c.6-.6.6-1.5 0-2.1z"/>
              </svg>
            </button>
          </span>
        </div>
      </div>  
</template>

<script>
export default {

}
</script>

<style>
.btn:hover {
    color: white;
}
</style>