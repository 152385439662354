<template>
      <section class="section has-margin">
        <div class="js-categories-overview teaser-categories" data-dialog-tabs>
          <div class="categories-intro categories-text">
            <h2 class="h2 categories-text__section">Productos</h2>
            <div class="categories-text__section">
            La eficiencia y los altos estándares son la clave de su éxito y el nuestro. En Sunrise Cargo, tenemos la experiencia para gestionar sus necesidades logísticas y de transporte, todo desde un solo lugar. Independientemente del tamaño y la naturaleza de su negocio, y de la complejidad de sus operaciones, nos ocuparemos de su logística por carretera, ferrocarril, aire y mar.
            </div>
            <div class="categories-text__section">
              <a href="/productall" class="lk lk--default lk-effect " tabindex="" >
                <svg class="icon icon--arrow-right-thick icon--svg " viewBox="0 0 129 200" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" d="M28.993 199.404l99.702-99.702L28.993 0 .709 28.285l71.418 71.417L.709 171.119z"/>
                </svg>
              <span>Ver más</span>
              </a>
            </div>
          </div>

            <div class="category-header category-header--rounded-corners" data-analytics="productsOverviewTab" data-tab-header="390" >
              <svg class="icon icon--land category-header__icon icon--svg" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
              viewBox="0 0 36 36" style="enable-background:new 0 0 36 36;" xml:space="preserve">
              <g>
                <path class="st0" d="M31.68,31.74H13.3c-0.36,0-0.65-0.28-0.65-0.63c0-0.35,0.29-0.63,0.65-0.63h18.38c0.36,0,0.65,0.28,0.65,0.63
                  C32.33,31.46,32.04,31.74,31.68,31.74z"/>
                <path class="st0" d="M35.31,6.4 M1.71,18.28l5.08,4.97L28.14,12c4.85-2.61,6.24-4.57,6.54-5.75c0.06-0.31-0.01-0.38-0.04-0.4
                  c-0.17-0.18-1.07-0.65-5.5,0.45l-4.8,2.43l-2.73,1.47c-0.14,0.08-0.31,0.1-0.46,0.05L8.31,6.64L6.35,7.81l8.54,6.26
                  c0.29,0.21,0.35,0.63,0.15,0.92c-0.05,0.08-0.12,0.14-0.18,0.18l-6.4,4.07c-0.15,0.1-0.34,0.13-0.52,0.07l-5.31-1.6L1.71,18.28z
                  M6.67,24.72c-0.16,0-0.32-0.06-0.44-0.18l-6.02-5.9c-0.26-0.25-0.27-0.67-0.03-0.94c0.04-0.04,0.09-0.08,0.14-0.11l1.9-1.16
                  c0.15-0.09,0.33-0.12,0.5-0.06l5.31,1.6l5.33-3.4L4.78,8.28C4.5,8.07,4.43,7.66,4.63,7.35c0.05-0.08,0.12-0.14,0.2-0.19L7.9,5.35
                  c0.14-0.09,0.32-0.11,0.48-0.06l12.85,3.6l2.52-1.35l4.88-2.47c0.05-0.02,0.09-0.04,0.14-0.05c4.86-1.22,6.15-0.75,6.77-0.13
                  c0.41,0.44,0.56,1.06,0.39,1.65l-0.01,0.04c-0.53,2.12-2.88,4.28-7.2,6.6L6.96,24.65C6.87,24.69,6.77,24.72,6.67,24.72z"/>
              </g>
              </svg>
              <span class="category-header__title">Transporte Aéreo</span>
              <svg class="icon icon--down-arrow category-header__accordion-icon hide-desk icon--svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 49.656 49.656">
                <path d="M1.414 14.535l2.828-2.828 20.586 20.585 20.586-20.585 2.828 2.828L24.828 37.95z"/>
                <path d="M24.828 39.364L0 14.536l4.242-4.242 20.586 20.585 20.586-20.586 4.242 4.242-24.828 24.829zm-22-24.828l22 22 22-22.001-1.414-1.414-20.586 20.586L4.242 13.122l-1.414 1.414z"/>
              </svg>
            </div>
            <div class="category-content category-content--first" data-tab="390">
              <picture class="category-content__picture lazy-placeholder-loader ">
                <source media="(max-width: 390px)" data-srcset="/imagenes/IMAGENES_HOME/aereo.png">
                <source media="(max-width: 479px)" srcset="/imagenes/IMAGENES_HOME/aereo.png">
                <source media="(max-width: 767px)" srcset="/imagenes/IMAGENES_HOME/aereo.png">
                <source media="(max-width: 1023px)" data-srcset="/imagenes/IMAGENES_HOME/aereo.png">
                <img data-src="/imagenes/IMAGENES_HOME/aereo.png" alt="Truck on the road" class="category-content__image js-lazy-load lazy-placeholder-loader__content">
              </picture>
              <div class="category-content__text categories-text category-content__text--rounded-corners">
                <h3 class="categories-text__section">Transporte Aéreo</h3>
                <div class="categories-text__section">
                  Sunrise Cargo es un proveedor de servicios de transporte aéreo líder en el mercado con una extensa red global. Nuestras soluciones de envío avanzadas e integradas garantizan el transporte de su carga hasta su destino, en cualquier parte del mundo.
                <br><br>
                  Contamos con una fuerte presencia en los principales hubs tales como Miami, Frankfurt, Madrid, Shanghai y Hong Kong, con precios atractivos y un eficiente servicio.
                <br><br>
                  A nivel nacional contamos con oficinas propias los aeropuertos de Bogotá, Medellín, Cali, Pereira, Barranquilla y Cartagena
                </div>
              </div>
            </div>


            <div class="category-header category-header--rounded-corners" data-analytics="productsOverviewTab" data-tab-header="392" >
              <svg class="icon icon--ocean category-header__icon icon--svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 39.6 32.8">
                <circle cx="6.9" cy="11.9" r=".6"></circle>
                <circle cx="6.9" cy="14.9" r=".6"></circle>
                <circle cx="16.8" cy="24.4" r=".6"></circle>
                <circle cx="20.5" cy="24.4" r=".6"></circle>
                <circle cx="24.2" cy="24.4" r=".6"></circle>
                <path d="M39.5 20.7c-.1-.2-.3-.3-.5-.3h-9.7v-4.3c0-.4-.3-.6-.7-.6H12.3c-.4 0-.6.3-.6.6v4.7l-1.3 1.3V8.6c0-.3-.3-.6-.6-.6h-.4V5.3c0-.4-.3-.6-.6-.6h-1c-1-.1-1.8.3-2.3 1l-.5.7V.6c0-.3-.3-.6-.7-.6-.3 0-.6.3-.6.6v7.3H1.8c-.4 0-.7.3-.7.6v3c0 .4.3.6.7.6h1V23H.7c-.4 0-.7.3-.7.6v8.5c0 .4.3.7.6.7h31.1c3 0 5.2-4.9 5.8-6.5l2-5.1c.1-.1.1-.3 0-.5zm-26.5-4h14.9v3.6H13v-3.6zM6.6 6.5c.3-.4.7-.6 1.2-.6h.3v2H5.5l1.1-1.4zM2.5 11V9.2h6.6V23h-5V11.6c0-.4-.3-.6-.6-.6h-1zm33.8 14.9c-1.1 2.7-3 5.6-4.6 5.6H1.3v-7.3h8.5c.1 0 .3 0 .4-.1l2.4-2.4H38l-1.7 4.2z"></path>
              </svg>
              <span class="category-header__title">Transporte Marítimo</span>
              <svg class="icon icon--down-arrow category-header__accordion-icon hide-desk icon--svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 49.656 49.656">
              <path d="M1.414 14.535l2.828-2.828 20.586 20.585 20.586-20.585 2.828 2.828L24.828 37.95z"/>
              <path d="M24.828 39.364L0 14.536l4.242-4.242 20.586 20.585 20.586-20.586 4.242 4.242-24.828 24.829zm-22-24.828l22 22 22-22.001-1.414-1.414-20.586 20.586L4.242 13.122l-1.414 1.414z"/>
              </svg>
            </div>
            <div class="category-content " data-tab="392">
              <picture class="category-content__picture lazy-placeholder-loader ">
                <source media="(max-width: 390px)" data-srcset="/imagenes/IMAGENES_HOME/maritimo.png">
                <source media="(max-width: 479px)" srcset="/imagenes/IMAGENES_HOME/maritimo.png">
                <source media="(max-width: 767px)" srcset="/imagenes/IMAGENES_HOME/maritimo.png">
                <source media="(max-width: 1023px)" data-srcset="/imagenes/IMAGENES_HOME/maritimo.png">
                <img data-src="/imagenes/IMAGENES_HOME/maritimo.png" alt="DB Schenker Employee Air Freight Product" class="category-content__image js-lazy-load lazy-placeholder-loader__content">
              </picture>
              <div class="category-content__text categories-text category-content__text--rounded-corners">
                <h3 class="categories-text__section">Transporte Marítimo</h3>
                <div class="categories-text__section">
                    A través del servicio de transporte marítimo despachamos más de 12.000 contenedores anuales desde y hacia los puertos de todo mundo, convirtiéndonos así en una empresa de transporte líder para sus envíos de carga marítima. Dondequiera que necesite que vayan sus envíos, nuestras soluciones innovadoras forman el puente hacia sus mercados de ventas y compras.  
                    <br><br>
                    Los viajes consistentes y confiables aseguran que sus envíos de carga marítima siempre lleguen a sus clientes de manera oportuna.
                </div>
              </div>
            </div>



            <div class="category-header category-header--rounded-corners" data-analytics="productsOverviewTab" data-tab-header="386" >
              <svg class="icon icon--ocean category-header__icon icon--svg" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                viewBox="0 0 36 36" style="enable-background:new 0 0 36 36;" xml:space="preserve">
                <g>
                      <path class="st0" d="M9.04,22.9c-1.15,0-2.08,0.93-2.08,2.08c0,1.15,0.93,2.08,2.08,2.08c1.15,0,2.08-0.93,2.08-2.08c0,0,0,0,0,0
                        C11.12,23.83,10.19,22.9,9.04,22.9z M9.04,28.39c-1.88,0-3.41-1.53-3.41-3.41c0-1.88,1.53-3.41,3.41-3.41
                        c1.88,0,3.41,1.53,3.41,3.41C12.45,26.86,10.92,28.39,9.04,28.39z"/>
                      <path class="st0" d="M9.08,25.02L9.08,25.02l0.67,0L9.08,25.02z M9.08,26.37c-0.74,0-1.35-0.6-1.35-1.35c0-0.74,0.6-1.35,1.35-1.35
                        c0.74,0,1.35,0.6,1.35,1.35C10.43,25.77,9.83,26.37,9.08,26.37z"/>
                      <path class="st0" d="M28.64,22.9c-1.15,0-2.08,0.93-2.08,2.08c0,1.15,0.93,2.08,2.08,2.08c1.15,0,2.08-0.93,2.08-2.08v0
                        C30.72,23.83,29.79,22.9,28.64,22.9z M28.64,28.39c-1.88,0-3.41-1.53-3.41-3.41c0-1.88,1.53-3.41,3.41-3.41s3.41,1.53,3.41,3.41
                        C32.05,26.86,30.52,28.39,28.64,28.39z"/>
                      <path class="st0" d="M28.6,25.02L28.6,25.02l0.67,0L28.6,25.02z M28.6,26.37c-0.74,0-1.35-0.6-1.35-1.35c0-0.74,0.6-1.35,1.35-1.35
                        c0.74,0,1.35,0.6,1.35,1.35C29.94,25.77,29.34,26.37,28.6,26.37z"/>
                      <rect x="11.86" y="24.27" class="st0" width="13.96" height="1.35"/>
                      <path class="st0" d="M6.32,25.61H0.67c-0.37,0-0.67-0.3-0.67-0.66V8.28c0-0.37,0.29-0.67,0.67-0.67H22.8
                        c0.37,0,0.67,0.29,0.67,0.67v12.08c0,0.37-0.29,0.66-0.67,0.66c-0.36,0-0.67-0.3-0.67-0.66V8.94H1.33v15.35h4.98
                        c0.36,0,0.67,0.3,0.67,0.66S6.68,25.61,6.32,25.61z"/>
                      <path class="st0" d="M34.22,25.61h-2.85c-0.36,0-0.66-0.3-0.66-0.67c0-0.37,0.29-0.67,0.66-0.67h2.85c0.18,0,0.35-0.09,0.46-0.23
                        v-2.31c-0.59-0.97-3.67-6.03-4.38-7.13l-0.34-0.53c-0.79-1.23-1.01-1.52-1.43-1.52h-5.74c-0.37,0-0.67-0.3-0.67-0.67
                        c0-0.36,0.29-0.67,0.67-0.67h5.74c1.19,0,1.73,0.85,2.54,2.13l0.34,0.52c0.79,1.22,4.35,7.07,4.49,7.32c0.06,0.1,0.1,0.22,0.1,0.35
                        v2.52C35.9,24.97,35.12,25.65,34.22,25.61z"/>
                      <path class="st0" d="M30.62,14.85h-6.25c-0.36,0-0.66-0.3-0.66-0.67c0-0.37,0.29-0.67,0.66-0.67h6.25c0.37,0,0.67,0.3,0.67,0.67
                        C31.29,14.54,31,14.85,30.62,14.85z"/>
                      <path class="st0" d="M35.09,22.92h-6.25c-0.36,0-0.66-0.3-0.66-0.67c0-0.37,0.29-0.67,0.66-0.67h6.25c0.36,0,0.66,0.3,0.66,0.67
                        C35.75,22.62,35.45,22.92,35.09,22.92z"/>
                      <path class="st0" d="M32.99,18.46h-6.76c-0.36,0-0.66-0.3-0.66-0.67v-1.78c0-0.37,0.29-0.67,0.66-0.67c0.36,0,0.67,0.3,0.67,0.67
                        v1.12h6.09c0.36,0,0.66,0.3,0.66,0.66C33.64,18.16,33.35,18.46,32.99,18.46z"/>
                      <path class="st0" d="M31.95,22.75c-0.36,0-0.66-0.3-0.66-0.66v-1.21c-0.01-0.47,0.16-0.92,0.5-1.25c0.38-0.33,0.87-0.51,1.37-0.5
                        l1.09,0c0.36,0,0.66,0.3,0.66,0.66c0,0.37-0.29,0.66-0.66,0.66h-1.11c-0.16-0.01-0.32,0.04-0.44,0.13
                        c-0.02,0.02-0.08,0.08-0.08,0.29v1.21C32.61,22.46,32.31,22.75,31.95,22.75z"/>
                      <path class="st0" d="M22.8,20.99H3.02c-0.37,0-0.67-0.3-0.67-0.67s0.29-0.67,0.67-0.67H22.8c0.37,0,0.67,0.3,0.67,0.67
                        S23.17,20.99,22.8,20.99z"/>
                    </g>              </svg>
              <span class="category-header__title">Transporte Terrestre</span>
              <svg class="icon icon--down-arrow category-header__accordion-icon hide-desk icon--svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 49.656 49.656">
                <path d="M1.414 14.535l2.828-2.828 20.586 20.585 20.586-20.585 2.828 2.828L24.828 37.95z"/>
                <path d="M24.828 39.364L0 14.536l4.242-4.242 20.586 20.585 20.586-20.586 4.242 4.242-24.828 24.829zm-22-24.828l22 22 22-22.001-1.414-1.414-20.586 20.586L4.242 13.122l-1.414 1.414z"/>
              </svg>
            </div>
            <div class="category-content " data-tab="386">
              <picture class="category-content__picture lazy-placeholder-loader ">
                <source media="(max-width: 390px)" data-srcset="/imagenes/IMAGENES_HOME/terrestre.png">
                <source media="(max-width: 479px)" srcset="/imagenes/IMAGENES_HOME/terrestre.png">
                <source media="(max-width: 767px)" srcset="/imagenes/IMAGENES_HOME/terrestre.png">
                <source media="(max-width: 1023px)" data-srcset="/imagenes/IMAGENES_HOME/terrestre.png">
                <img data-src="/imagenes/IMAGENES_HOME/terrestre.png" alt="Ocean Freight Container Ship" class="category-content__image js-lazy-load lazy-placeholder-loader__content">
              </picture>
              <div class="category-content__text categories-text category-content__text--rounded-corners">
                <h3 class="categories-text__section">Transporte Terrestre</h3>
                <div class="categories-text__section">
                  Sunrise Cargo selecciona cuidadosamente proveedores de transporte terrestre habilitados que cumplan con los más altos estándares de seguridad y calidad. Obteniendo el alcance que necesita para mantenerse por delante de la competencia con una alta gama de soluciones de transporte terrestre de calidad.
                  <br><br>
                  Ya sea que necesite transporte por carretera, nuestras soluciones de envío terrestre llevarán sus envíos donde sea que necesiten estar, de manera puntual y confiable. No importa si necesita un solo envío de paquete o si desea que gestionemos toda su cadena de suministro de superficie. Tenemos proveedores de transporte terrestre habilitados que puede mover cualquier mercancía a cualquier lugar a nivel nacional.
                </div>
              </div>
            </div>


            
            <div class="category-header category-header--rounded-corners" data-analytics="productsOverviewTab" data-tab-header="388" >
              <svg class="icon icon--contract-log category-header__icon icon--svg" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                viewBox="0 0 36 36" style="enable-background:new 0 0 36 36;" xml:space="preserve">
                <g>
                <polyline class="st0" points="29.57,13.55 32.88,13.55 32.88,8.49 	"/>
                <polyline class="st0" points="29.57,20.07 34.6,20.07 34.6,22.64 	"/>
                <line class="st0" x1="29.32" y1="16.9" x2="33.36" y2="16.9"/>
                <path class="st1" d="M34.15,17h0.9v-0.63h-0.9V17z M35.53,18.18h-1.86c-0.26,0-0.47-0.27-0.47-0.59v-1.8
                  c0-0.33,0.21-0.59,0.47-0.59h1.86c0.26,0,0.47,0.27,0.47,0.59v1.8C36,17.92,35.79,18.18,35.53,18.18z"/>
                <path class="st1" d="M32.43,7.57h0.9V6.94h-0.9V7.57z M33.81,8.75h-1.86c-0.26,0-0.47-0.27-0.47-0.59v-1.8
                  c0-0.33,0.21-0.59,0.47-0.59h1.86c0.26,0,0.47,0.27,0.47,0.59v1.8C34.28,8.48,34.07,8.75,33.81,8.75z"/>
                <path class="st1" d="M34.15,24.26h0.9v-0.63h-0.9V24.26z M35.53,25.43h-1.86c-0.26,0-0.47-0.27-0.47-0.59v-1.8
                  c0-0.33,0.21-0.59,0.47-0.59h1.86c0.26,0,0.47,0.27,0.47,0.59v1.8C36,25.17,35.79,25.43,35.53,25.43z"/>
                <polyline class="st2" points="2.99,5.34 2.99,3.65 23.18,3.65 23.18,5.34 	"/>
                <polyline class="st2" points="21.94,15.57 21.94,26.96 21.94,27.43 29.32,27.43 29.32,8.47 0.95,8.47 0.95,27.43 3.17,27.43 
                  3.17,13.29 21.94,13.29 	"/>
                <rect y="31.26" class="st3" width="36" height="1.09"/>
                <rect y="29.2" class="st3" width="36" height="1.09"/>
                <rect x="14.48" y="24.71" class="st3" width="3.4" height="3.56"/>
                <rect x="14.48" y="20.61" class="st3" width="3.4" height="3.56"/>
                <rect x="10.9" y="24.71" class="st3" width="3.4" height="3.56"/>
                <rect x="7.37" y="24.71" class="st3" width="3.4" height="3.56"/>
                <rect x="3.79" y="24.71" class="st3" width="3.4" height="3.56"/>
                <rect x="3.77" y="20.41" class="st3" width="3.4" height="3.56"/>
                <rect x="3.77" y="16.18" class="st3" width="3.4" height="3.56"/>
                <path class="st4" d="M13.71,20.61h-2.23c-0.32,0-0.58,0.26-0.58,0.58v2.39c0,0.32,0.26,0.58,0.58,0.58h2.23
                  c0.32,0,0.58-0.26,0.58-0.58V21.2C14.3,20.87,14.04,20.61,13.71,20.61z"/>
                <path class="st5" d="M5.75,17.5H5.19c-0.1,0-0.18,0.08-0.18,0.18v0.56c0,0.1,0.08,0.18,0.18,0.18h0.56c0.1,0,0.18-0.08,0.18-0.18
                  v-0.56C5.93,17.58,5.85,17.5,5.75,17.5z"/>
                <path class="st5" d="M5.75,21.73H5.19c-0.1,0-0.18,0.08-0.18,0.18v0.56c0,0.1,0.08,0.18,0.18,0.18h0.56c0.1,0,0.18-0.08,0.18-0.18
                  v-0.56C5.93,21.81,5.85,21.73,5.75,21.73z"/>
                <path class="st5" d="M12.88,21.93h-0.56c-0.1,0-0.18,0.08-0.18,0.18l0,0.56c0,0.1,0.08,0.18,0.18,0.18h0.56
                  c0.1,0,0.18-0.08,0.18-0.18v-0.56C13.06,22.01,12.98,21.93,12.88,21.93z"/>
                <path class="st5" d="M5.75,26.03H5.19c-0.1,0-0.18,0.08-0.18,0.18v0.56c0,0.1,0.08,0.18,0.18,0.18h0.56c0.1,0,0.18-0.08,0.18-0.18
                  v-0.56C5.93,26.11,5.85,26.03,5.75,26.03z"/>
                <path class="st5" d="M9.35,26.03H8.79c-0.1,0-0.18,0.08-0.18,0.18v0.56c0,0.1,0.08,0.18,0.18,0.18h0.56c0.1,0,0.18-0.08,0.18-0.18
                  v-0.56C9.53,26.11,9.45,26.03,9.35,26.03z"/>
                <path class="st5" d="M12.9,26.03h-0.56c-0.1,0-0.18,0.08-0.18,0.18v0.56c0,0.1,0.08,0.18,0.18,0.18h0.56c0.1,0,0.18-0.08,0.18-0.18
                  v-0.56C13.09,26.11,13.01,26.03,12.9,26.03z"/>
                <path class="st5" d="M16.46,26.03H15.9c-0.1,0-0.18,0.08-0.18,0.18v0.56c0,0.1,0.08,0.18,0.18,0.18h0.56c0.1,0,0.18-0.08,0.18-0.18
                  v-0.56C16.64,26.11,16.56,26.03,16.46,26.03z"/>
                <path class="st5" d="M16.46,21.93H15.9c-0.1,0-0.18,0.08-0.18,0.18v0.56c0,0.1,0.08,0.18,0.18,0.18h0.56c0.1,0,0.18-0.08,0.18-0.18
                  v-0.56C16.64,22.01,16.56,21.93,16.46,21.93z"/>
              </g>
              </svg>
              <span class="category-header__title">Logística & Distribución</span>
              <svg class="icon icon--down-arrow category-header__accordion-icon hide-desk icon--svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 49.656 49.656">
                <path d="M1.414 14.535l2.828-2.828 20.586 20.585 20.586-20.585 2.828 2.828L24.828 37.95z"/>
                <path d="M24.828 39.364L0 14.536l4.242-4.242 20.586 20.585 20.586-20.586 4.242 4.242-24.828 24.829zm-22-24.828l22 22 22-22.001-1.414-1.414-20.586 20.586L4.242 13.122l-1.414 1.414z"/>
              </svg>
            </div>
            <div class="category-content " data-tab="388">
                  <picture class="category-content__picture lazy-placeholder-loader ">
                <source media="(max-width: 390px)" data-srcset="/imagenes/IMAGENES_HOME/almacenamiento-y-distribucion.png">
                <source media="(max-width: 479px)" srcset="/imagenes/IMAGENES_HOME/almacenamiento-y-distribucion.png">
                <source media="(max-width: 767px)" srcset="/imagenes/IMAGENES_HOME/almacenamiento-y-distribucion.png">
                <source media="(max-width: 1023px)" data-srcset="/imagenes/IMAGENES_HOME/almacenamiento-y-distribucion.png">
                  <img data-src="/imagenes/IMAGENES_HOME/almacenamiento-y-distribucion.png" alt="Contract Logistics Warehouse" class="category-content__image js-lazy-load lazy-placeholder-loader__content">
                </picture>
              <div class="category-content__text categories-text category-content__text--rounded-corners">
                <h3 class="categories-text__section">Logística & Distribución</h3>
                <div class="categories-text__section">
                    Sunrise Cargo S.A.S. opera sus propias bodegas, que suman un total de 17.000 metros cuadrados en Bogotá.
                  <br><br>
                    Las bodegas de Sunrise Cargo S.A.S. se encuentran en Siberia, el eje central de logística de la ciudad de Bogotá y dentro del complejo industrial San Cayetano, en las cercanías del aeropuerto El Dorado. Contamos con un sofisticado sistema de seguridad y tecnología de almacenamiento, manejo de inventarios y distribución de última generación.
                  <br><br>
                    Con estas instalaciones Sunrise Cargo S.A.S. ofrece la cadena logística completa, incluyendo picking & packing, empaque y distribución a nivel nacional.
                </div>
              </div>
            </div>
            <div class="category-header category-header--rounded-corners"	data-analytics="productsOverviewTab"	data-tab-header="394"	>
              <svg class="icon icon--contract-log category-header__icon icon--svg" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                viewBox="0 0 36 36" style="enable-background:new 0 0 36 36;" xml:space="preserve">
                  <g>
                    <path class="st0" d="M32.44,17.72h0.14c0.55,0,1.01-0.46,1.01-1.01c0-0.55-0.46-1-1.01-1h-5.01c0.01-0.07,0.01-0.15,0.01-0.22
                      c0-1.69-1.38-3.07-3.08-3.07c-1.71,0-3.08,1.37-3.08,3.07c0,0.08,0,0.15,0.01,0.22h-5.18c-0.55,0-1,0.45-1,1
                      c0,0.55,0.45,1.01,1,1.01h0.21l-3.46,10.29v0.01c0,1.15,0.4,2.22,1.08,3.06c0.9,1.13,2.29,1.84,3.84,1.84
                      c1.57,0,2.96-0.73,3.86-1.86c0.67-0.83,1.07-1.89,1.07-3.03v-0.01l-3.56-10.29h10.34l-3.46,10.29v0.01c0,1.15,0.41,2.22,1.08,3.06
                      c0.9,1.13,2.29,1.84,3.84,1.84c1.57,0,2.96-0.73,3.86-1.86C35.6,30.22,36,29.16,36,28.02v-0.01L32.44,17.72z M20.72,28.01H15.1
                      l2.77-8.21L20.72,28.01z M28.26,28.01l2.77-8.21l2.84,8.21H28.26z"/>
                    <path class="st0" d="M15.63,12.9l-8.43,0c-0.57,0-1.04-0.47-1.04-1.04v0c0-0.57,0.47-1.04,1.04-1.04h8.43
                      c0.57,0,1.04,0.47,1.04,1.04v0C16.67,12.44,16.2,12.9,15.63,12.9z"/>
                    <path class="st0" d="M12.05,19.8H7.2c-0.57,0-1.04-0.47-1.04-1.04v0c0-0.57,0.47-1.04,1.04-1.04h4.85c0.57,0,1.04,0.47,1.04,1.04v0
                      C13.09,19.33,12.62,19.8,12.05,19.8z"/>
                    <path class="st0" d="M10.73,26.69l-3.53,0c-0.57,0-1.04-0.47-1.04-1.04v0c0-0.57,0.47-1.04,1.04-1.04h3.53
                      c0.57,0,1.04,0.47,1.04,1.04v0C11.77,26.22,11.31,26.69,10.73,26.69z"/>
                    <path class="st0" d="M32.17,3.77v9.56c0,0.38-0.31,0.7-0.69,0.7h-0.59c-0.38,0-0.7-0.32-0.7-0.7V5.05H1.98v25.89h8.94
                      c0.39,0,0.7,0.32,0.7,0.7v0.58c0,0.39-0.31,0.7-0.7,0.7H0.7c-0.38,0-0.7-0.31-0.7-0.7V3.77c0-0.38,0.32-0.69,0.7-0.69h30.78
                      C31.86,3.08,32.17,3.39,32.17,3.77z"/>
                  </g>
              </svg>
              <span class="category-header__title">Aduana</span>
              <svg class="icon icon--down-arrow category-header__accordion-icon hide-desk icon--svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 49.656 49.656">
                <path d="M1.414 14.535l2.828-2.828 20.586 20.585 20.586-20.585 2.828 2.828L24.828 37.95z"/>
                <path d="M24.828 39.364L0 14.536l4.242-4.242 20.586 20.585 20.586-20.586 4.242 4.242-24.828 24.829zm-22-24.828l22 22 22-22.001-1.414-1.414-20.586 20.586L4.242 13.122l-1.414 1.414z"/>
              </svg>
            </div>
            <div class="category-content " data-tab="394">
              <picture class="category-content__picture lazy-placeholder-loader ">
                <source media="(max-width: 390px)" data-srcset="/imagenes/IMAGENES_HOME/seguro.png">
                <source media="(max-width: 479px)" srcset="/imagenes/IMAGENES_HOME/seguro.png">
                <source media="(max-width: 767px)" srcset="/imagenes/IMAGENES_HOME/seguro.png">
                <source media="(max-width: 1023px)" data-srcset="/imagenes/IMAGENES_HOME/seguro.png">
                <img data-src="/imagenes/IMAGENES_HOME/seguro.png" alt="Lead Logistics DB Schenker Employees " class="category-content__image js-lazy-load lazy-placeholder-loader__content"> 	
              </picture>
              <div class="category-content__text categories-text category-content__text--rounded-corners">
                <h3 class="categories-text__section">Aduana</h3>
                <div class="categories-text__section">
                  Sunrise Cargo S.A.S. ha seleccionado cuidadosamente agencias de Aduanas Nivel 1, con operación propia y directa en Colombia a nivel nacional. Los cuales tienen programas de calidad y seguridad certificados hace más de 10 años.
                <br><br>
                  Las operaciones de nacionalización ejecutadas por las agencias de aduana previamente seleccionadas son coordinadas y supervisadas por nuestro propio equipo, pudiendo así garantizar un óptimo servicio a los clientes de Sunrise Cargo.
                </div>
              </div>
            </div>
            <div class="category-header category-header--rounded-corners" data-analytics="productsOverviewTab" data-tab-header="396" >

              <svg class="icon icon--special-prod category-header__icon icon--svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 31.4 33.4">
                <path d="M30.7 9.4c.4 0 .7-.2.7-.6V5.5c0-.4-.3-.7-.6-.7H12c-.4 0-.6.3-.6.6V8L5.2 2l.9-.9c.3-.3.3-.7 0-.9s-.7-.3-.9 0l-.8.7-.1.2V1L4 .7c-.3-.3-.7-.3-1 0s-.2.7 0 .9l.4.4L.7 4.7c-.9.9-.9 2.4 0 3.3.1.1.3.2.4.2h1.5c.9 0 1.7-.4 2.1-1.2l7.1 13.6c-.1.1-.2.3-.2.4v5.4c0 .2.1.4.3.6h.2v1.8H12c-.4 0-.6.3-.6.7v3.2c0 .4.3.7.6.7h18.7c.4 0 .7-.3.7-.6v-3.2c0-.4-.3-.7-.6-.7h-.3v-11l.2-.1c.2-.1.4-.3.3-.6v-5.4c0-.2-.1-.4-.3-.6-.1 0-.1-.1-.2-.1V9.4h.2zm-18-3.3h17.4v2H12.7v-2zm1 19l-.5.3v-3.9l.5-.3 15.5-8.1.5-.3v3.9l-.5.3-15.5 8.1zm0-5.4V9.4h15.4v2.3l-15.4 8zM5.2 4.8c-.2-.3-.6-.4-.9-.2-.2.1-.4.3-.4.5v.6c0 .7-.5 1.2-1.2 1.2H1.4c-.2-.4-.2-.9.2-1.3l2.7-2.7 7.7 7.7c.1.1.2.1.3.2v7.7L5.2 4.8zm24.9 27.3H12.7v-2h17.4v2zm-1-3.3H13.6v-2.3l15.5-8.1v10.4z"/>
              </svg>


              
              <span class="category-header__title">Proyectos</span>
              <svg class="icon icon--down-arrow category-header__accordion-icon hide-desk icon--svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 49.656 49.656">
                <path d="M1.414 14.535l2.828-2.828 20.586 20.585 20.586-20.585 2.828 2.828L24.828 37.95z"/>
                <path d="M24.828 39.364L0 14.536l4.242-4.242 20.586 20.585 20.586-20.586 4.242 4.242-24.828 24.829zm-22-24.828l22 22 22-22.001-1.414-1.414-20.586 20.586L4.242 13.122l-1.414 1.414z"/>
              </svg>
            </div>
            <div class="category-content category-content--sixth" data-tab="396">
                <picture class="category-content__picture lazy-placeholder-loader ">
                <source media="(max-width: 390px)" data-srcset="/imagenes/IMAGENES_HOME/proyectos-y-carga-extradimensionada.png">
                <source media="(max-width: 479px)" srcset="/imagenes/IMAGENES_HOME/proyectos-y-carga-extradimensionada.png">
                <source media="(max-width: 767px)" srcset="/imagenes/IMAGENES_HOME/proyectos-y-carga-extradimensionada.png">
                <source media="(max-width: 1023px)" data-srcset="/imagenes/IMAGENES_HOME/proyectos-y-carga-extradimensionada.png">
                  <img data-src="/imagenes/IMAGENES_HOME/proyectos-y-carga-extradimensionada.png" alt="Special Products Truck Crane" class="category-content__image js-lazy-load lazy-placeholder-loader__content">
                </picture>
              <div class="category-content__text categories-text category-content__text--rounded-corners">
                <h3 class="categories-text__section">Proyectos</h3>
                <div class="categories-text__section">
                  Cuenta con empresas de transporte especializadas en logísticas para la ejecución de
                            proyectos que incluyen transporte de carga extrapesada y/o extra dimensionada en
                            Colombia, así como el suministro de grúas y equipo de izaje para montajes de obras
                            industriales y civiles, con equipos de última tecnología, buscando satisfacer las
                            necesidades del mercado.
                </div>
              </div>
            </div>
        </div> 
      </section>
</template>

<script>
export default {


}
</script>

<style>

</style>